<template>
  <div class="list">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click="getPdf(item)"
    >
      <div class="left">
        <img src="../assets/pdf-icon.png" alt="" />
      </div>
      <div class="msg">
        <p class="name">{{ item.name }}</p>
        <div class="bottom">
          <p class="time">{{ item.time }}</p>
          <p class="internal-memory">{{ item.size }}KB</p>
        </div>
      </div>
      <div class="right">
        <img src="../assets/arrowleft-icon.png" alt="" />
      </div>
    </div>
    <div
      class="btn"
      v-if="$route.query.channel && $route.query.type == 'productTerms'"
    >
      <p @click="insurePackage">我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
import {
  getRateTableFileByProductId,
  getProductAttachment,
} from "../utils/api";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  async created() {
    let params = {
      id: sessionStorage.getItem("productId"),
    };
    if (this.$route.query.type == "rateTable") {
      document.title = "费率表";
      await this.getRateTableFileByProductId(params);
    } else {
      document.title = "保险条款";
      await this.getProductAttachment(params);
    }
  },
  methods: {
    //跳转投保
    insurePackage() {
      this.$router.push({
        path: "/insurePackage",
      });
    },

    //查看pdf
    getPdf(item) {
      this.$router.push({
        path: "/pdf",
        query: {
          id: item.id,
          productId: sessionStorage.getItem("productId"),
        },
      });
    },
    //费率表
    getRateTableFileByProductId(params) {
      getRateTableFileByProductId(params).then((res) => {
        if (res.success) {
          this.list = res.result;
        }
      });
    },
    //产品条款
    getProductAttachment(params) {
      getProductAttachment(params).then((res) => {
        if (res.success) {
          this.list = res.result;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}
.list {
  padding: 0 20px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
  .left {
    img {
      width: 24px;
    }
  }
  .msg {
    flex: 1;
    width: 100%;
    margin: 0 17px;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: justify;
    }
    .bottom {
      display: flex;
      align-items: center;
      margin-top: 8px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 12px;
      }
    }
  }
  .right {
    img {
      width: 10px;
    }
  }
}
</style>